import "bootstrap/scss/bootstrap-grid.scss";
import 'swiper/swiper-bundle.css';
import 'aos/dist/aos.css';
import Swiper, { Navigation, Pagination,EffectFade } from 'swiper';
Swiper.use([Navigation, Pagination, EffectFade]);
import AOS from 'aos/dist/aos.js';

// Swiper.use([Navigation, Pagination]);

document.addEventListener('DOMContentLoaded', ()=> {
  AOS.init({
    once: true,
    duration: 1200,
  });
});

function documentHeight () {
	const doc = document.documentElement
	doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
}
  window.addEventListener('resize', documentHeight)
  documentHeight();

(function($) {
  // Submit the form when a checkbox is clicked
  $('.video-filters input[type="checkbox"]').on('change', function() {
    $(this).closest('form').submit();
  });
})(jQuery);


(function ($, root, undefined) {
  $(function () {

    function formLabels() {
      $('.wpcf7 .form-input, .wpcf7 .form-textarea, .wpcf7 .form-select').focus(function () {
        $(this).parent().parent().addClass('active');
      }).blur(function () {
        var cval = $(this).val()
        if (cval.length < 1) {
          $(this).parent().parent().removeClass('active');
        }
      })
    }

    formLabels();

    function navFollow(){

      var lastScrollTop = 0;
    
    
    
        jQuery(window).scroll(function (event) {
    
        
    
          var st = jQuery(this).scrollTop();
    
        var delta = 5;
        if (Math.abs(st - lastScrollTop) <= delta)
          return;
    
          if (st < 60 || st > lastScrollTop) {
    
            jQuery(".header").removeClass("scrolled-up");
        jQuery(".header").addClass("scrolled-down");
      } else if (st == lastScrollTop) {
        
        
      } else if (st < lastScrollTop) {
        jQuery(".header").removeClass("scrolled-down");
        jQuery(".header").addClass("scrolled-up");
      
      }
    
    
    
          if (st < 60) {
        jQuery(".header").removeClass("scrolled-up");
        jQuery(".header").removeClass("scrolled-down");
      
    
          }
    
    
          lastScrollTop = st;
    
        });
    }
    navFollow();


    let pathname = window.location.pathname;
    let ajaxURL = '/wp-admin/admin-ajax.php';

    if(pathname.includes('bizea')) {
      ajaxURL = '/bizea/wp-admin/admin-ajax.php';
    }

    //lazyLoadFn();

    function paginationPosts() {
      $('.blog-page .blog-page__posts-load-more').click(function(){
      
        let current = $(this).data("current");
        const except = $(this).data("not");
        const max = $(this).data("max");

        current += 1;
        $(this).data("current", current)
        $.ajax({
          type: "POST",
          url: ajaxURL,
          data: {
              action: 'shows_posts_action',
              current : current,
              except: except,
          },
          beforeSend: function() {
            console.log(max, current);
            if(max == current){
              
              $('.blog-page .blog-page__posts-load-more').fadeOut();
            }else{
              $('.blog-page .blog-page__posts-load-more').addClass('disabled');
            }
          },
          success: function (data) {
            if( data ) {
              //var parsedJSON = JSON.parse(data);
              $( '.blog-page .blog-page__posts-container .row' ).append(data);
              $('.blog-page .blog-page__posts-load-more').removeClass('disabled');
            }
          },
          error: function (response) {
            console.log("Error");
          }
        });
      });
    }

    paginationPosts();


    // function postsCategories() {
    //   $('#news .categories-list__item').click(function(){
    //     const category_slug = $(this).data("catslug");
        
    //     $.ajax({
    //       type: "POST",
    //       url: ajaxURL,
    //       data: {
    //           action: 'shows_posts_action',
    //           current : 0,
    //           goto : 1,
    //           category_slug: category_slug,
    //       },

    //       success: function (data) {
    //         if( data ) {
    //           //var parsedJSON = JSON.parse(data);
    //           $( '#news .blog-page__posts-list' ).html(data);
    //         }
    //       },
    //       error: function (response) {
    //         console.log("Error");
    //       }
    //     });
    //   });
    // }

    // postsCategories();

    function searchForm() {
      var searchForm = $('#search-form');
      var searchInput = $('#search');
      var searchResults = $('#search-results');
      
      searchInput.on('keyup', function(event) {
      event.preventDefault();
      var searchQuery = searchInput.val();
        
      // Sprawdź, czy coś zostało wpisane w polu wyszukiwania
      if (searchQuery.length > 2) {
      var searchQuery = searchInput.val();
        $.ajax({
          url: ajaxURL,
          data: {
          action: 'search_posts_and_products',
          search_query: searchQuery
        },
        success: function(response) {
          if(response){
            searchResults.empty();
            searchResults.append(response);
          }
        }
        });
      }else{
        searchResults.empty();
      }
      });
      }
      
      searchForm();

      const clearSearch = document.querySelector('#cleanSearch');

      if(clearSearch){
        clearSearch.addEventListener('click', () => {
          var searchInput = $('#search');
          var searchResults = $('#search-results');
          searchResults.empty();
          searchInput.val('');
        })
      }


    function paginationVideos() {
      $('#videos .pagination .page-item').click(function(){
       
        const category_slug = $("#videos .categories-list__item.active").data("catslug");
       
        const current = $(this).find('button').data("current");
        const goto = $(this).find('button').data("goto");

        const indusCat = $('#industry-cat-list').data("allslug")
        const vidCat = $('#video-cat-list').data("allslug")

        
        $.ajax({
          type: "POST",
          url: ajaxURL,
          data: {
              action: 'show_videos_posts_action',
              current : current,
              goto : goto,
              catList1: indusCat,
              catList2: vidCat,
          },
  
          success: function (data) {
            if( data ) {
              //var parsedJSON = JSON.parse(data);
              // console.log(data);
              $( '#videos .all-videos__posts-list' ).html(data);

              paginationVideos();
              videoPopup();

            }
          },
          error: function (response) {
            console.log("Error");
          }
        });
      });
    }

    paginationVideos();


    function videosCategories() {
      $('#videos .categories-list__item').click(function(){
        const category_slug = $(this).data("catslug");
        $(this).toggleClass('selected');

        let catList = $(this).parent('.categories-list').data("allslug");

        
        if(catList == ''){
          catList = [category_slug];
        }else{
          catList = catList.split(',');
          if(catList.includes(category_slug)){
            catList.splice(catList.indexOf(category_slug), 1);
          }else{
            catList.push(category_slug);
          }
        }

        catList = catList.join();

        $(this).parent('.categories-list').data("allslug", catList);

        const indusCat = $('#industry-cat-list').data("allslug")
        const vidCat = $('#video-cat-list').data("allslug")


        $.ajax({
          type: "POST",
          url: ajaxURL,
          data: {
              action: 'show_videos_posts_action',
              current : 0,
              goto : 1,
              catList1: indusCat,
              catList2: vidCat,
          },

          success: function (data) {
            if( data ) {
              //var parsedJSON = JSON.parse(data);
              $( '#videos .all-videos__posts-list' ).html(data);
              paginationVideos();
              videoPopup();
            }
          },
          error: function (response) {
            console.log("Error", response);
          }
        });
      });
    }

    videosCategories();

    function revealPosts (){
      let i = 0;
      let posts = $('li.downloads__list-item:not(.reveal), article.job-offers__offer:not(.reveal)');
      // if($('body.post-type-archive-product').length > 0){
      //     posts = $('');
  
      // }
      setInterval(function(){
          if(i >= posts.length) return false;
          let elem = posts[i];
          $(elem).fadeIn("slow");
          $(elem).addClass('reveal');
          i++;
      },200);
  }

    function downloads() {
      $('#download-more').click(function(){
        let postPerPage = $( this ).data('qty');
        $.ajax({
          type: "POST",
          url: ajaxURL,
          data: {
              action: 'show_more_downloads_action',
              offset: postPerPage,
          },
          beforeSend: function() {
            $( '#download-more' ).addClass('disabled');
            $( '#download-more' ).fadeOut();
          },
          success: function (data) {
            if( data ) {
              //var parsedJSON = JSON.parse(data);
              $( '.downloads__list' ).append(data);
              revealPosts();
            }
          },
          error: function (response) {
            console.log(response);
          }
        });
      });
    }

    downloads();

    // function jobOffers() {
    //   $('#job-offers-ajax').click(function(){

    //     let postsPerPage = $( this ).data('posts');
    //     let currentPage = $( '#job-offers' ).data('curpage');
    //     let lastPage = $( this ).data('pages');

    //     $( '#job-offers' ).data('curpage', currentPage + 1);

    //     $.ajax({
    //       type: "POST",
    //       url: ajaxURL,
    //       data: {
    //           action: 'show_more_offers_action',
    //           postsPerPage: postsPerPage,
    //           currentPage: currentPage,
    //       },
    //       beforeSend: function() {
    //       if(currentPage == lastPage -1){
    //         $( '#job-offers-ajax' ).fadeOut();
    //       }
            
    //       },
    //       success: function (data) {
    //         if( data ) {
    //           //var parsedJSON = JSON.parse(data);
    //           $( '.job-offers__offers-rows' ).append(data);
    //           revealPosts();
    //         }
    //         offerPopup();
    //       },
    //       error: function (response) {
    //         console.log("Error");
    //       }
    //     });
    //   });
    // }

    // jobOffers();


  });


})(jQuery, this);

jQuery( function( $ ) {
  let pathname = window.location.pathname;
  let ajaxURL = '/wp-admin/admin-ajax.php';

  if(pathname.includes('bizea')) {
    ajaxURL = '/bizea/wp-admin/admin-ajax.php';
  }
  function productPagination() {
  $( document ).on( 'click', '.product .pagination .page-item button', function( event ) {
    event.preventDefault();

    
    var page = $( this ).data( 'goto' );
    var slug = $( "#security" ).data( 'slug' );
    var tax = $( "#security" ).data( 'tax' );
    var security = $( '#security' ).val();


    $.ajax( {
      url: ajaxURL,
      type: 'POST',
      data: {
        action: 'woocommerce_pagination',
        page: page,
        security: security,
        slug: slug,
        tax: tax,
      },
      beforeSend: function() {
        $( '#products-ajax-container' ).addClass( 'loading' );
      },
      success: function( response ) {
        
        $("html, body").animate({ scrollTop: 0 }, "slow");
        $( '#products-ajax-container' ).removeClass( 'loading' );
        $( '#products-ajax-container' ).html( response );
        $( '#current-page' ).val( page );
      },
      error: function( xhr, textStatus, errorThrown ) {
        console.log( xhr.responseText );
      }
    } );
  } );
}

productPagination();
} );


/* BIZEA POPUPS */



function videoPopup() {


  const singleVideo = document.querySelectorAll('.videos__video');
  const singleVideoPopup = document.querySelector('.popup');
  const singleVideoContent = document.querySelector('.popup__wrapper');

  
  singleVideo.forEach((el) => {
    el.addEventListener('click', (e) => {

      var yScrollWidth = (window.innerWidth - document.body.clientWidth) + 'px';
      html.style.paddingRight = yScrollWidth;
      html.classList.add('popup--open');
      document.querySelector('.header').classList.remove('fixed');

      singleVideoPopup.classList.add('active');

      let videoID = e.currentTarget.dataset.video;

      let postName = e.currentTarget.dataset.name;

      if(postName){
        let postContent = e.currentTarget.dataset.content;
        let btn = e.currentTarget.dataset.btn;
        btn = btn.split(',');
        let btnHtml = '';

        if(btn.length > 1){
          btnHtml = `<a href="${btn[0]}" class="btn btn--fill">${btn[1]}</a>`
        }

        singleVideoContent.innerHTML = `
        <div class="iframe-wrapper">
        <iframe class="iframe" src="https://www.youtube.com/embed/${videoID}" frameborder="0" allow="encrypted-media"
        allowfullscreen></iframe></div>
          <h3 class="videos__popup-video-name">${postName}</h3>
          <div class="videos__popup-video-content">${postContent}</div>
          ${btnHtml}
        `;
    }else{
      singleVideoContent.innerHTML = `
      <div class="iframe-wrapper">
      <iframe class="iframe" src="https://www.youtube.com/embed/${videoID}" frameborder="0" allow="encrypted-media"
      allowfullscreen></iframe></div>
    `;
    }

      const singleVideoPopupClose = document.querySelector('.popup-close-btn');

      singleVideoPopupClose.addEventListener('click', () => {
        singleVideoPopup.classList.remove('active');

        setTimeout(()=>{
          singleVideoContent.innerHTML = ``;
          html.style.paddingRight = 0;
          html.classList.remove('popup--open');
        }, 300);
      });

    });
  });
}

videoPopup();

// product popup

function productPopupSlider() {


  const singleProduct = document.querySelector('.product-slider-top:not(.video-slide)');
if(singleProduct){
  const sliderImgIds = document.querySelector('.product-slider-top').dataset.imgIds;
  const singleProductPopup = document.querySelector('.popup');
  const singleProductContent = document.querySelector('.popup__wrapper');

  let pathname = window.location.pathname;
  let ajaxURL = '/wp-admin/admin-ajax.php';

  if(pathname.includes('bizea')) {
    ajaxURL = '/bizea/wp-admin/admin-ajax.php';
  }

    singleProduct.addEventListener('click', (e) => {

      let  el = singleProduct.querySelector('.swiper-slide-active .product__img')
      if(el.querySelector('.videos__video')) return;
      
      var yScrollWidth = (window.innerWidth - document.body.clientWidth) + 'px';
      html.style.paddingRight = yScrollWidth;
      html.classList.add('popup--open');
      html.classList.add('popup--slider');
      document.querySelector('.header').classList.remove('fixed');

      singleProductPopup.classList.add('active');
      singleProductPopup.classList.add('product-slider');

      singleProductContent.innerHTML = ` <div class="swiper product-slider-popup">
      <div class="swiper-wrapper"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
      <div class="swiper-pagination"></div>
      </div>`;

      $.ajax( {
        url: ajaxURL,
        type: 'POST',
        data: {
          action: 'get_images', 
          image_ids: sliderImgIds 
        },
        success: function(response) {
          // The server should return the list of image IDs as a JSON array
  
          const imagesArray = JSON.parse(response);

          imagesArray.forEach(img => {
            singleProductContent.querySelector('.swiper-wrapper').innerHTML += img
          })
          // Use the imageIds array as needed (e.g., display the IDs, perform further actions, etc.)
        },
        error: function(xhr, status, error) {
          console.error('Error retrieving image IDs: ' + error);
        }
      });
   
      // initialise slider
      const productSliderSwiper = new Swiper( '.swiper.product-slider-popup', {
        spaceBetween: 0,
        slidesPerView: 1,
        centeredSlides: true,
      //   allowTouchMove:false,
      
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
      
      } );
      productSliderSwiper.slideTo(el.dataset.slideId)
      
      
      const singleProductPopupClose = document.querySelector('.popup-close-btn');

      singleProductPopupClose.addEventListener('click', () => {
        singleProductPopup.classList.remove('active');

        setTimeout(()=>{
          html.style.paddingRight = 0;
          html.classList.remove('popup--open');
          html.classList.remove('popup--slider');
        }, 300);
      });

    });
  }
}

productPopupSlider();



function offerPopup() {


  const singleoffer = document.querySelectorAll('.job-offer__btn');
  const singleOfferPopup = document.querySelector('.popup');
  const singleOfferContent = document.querySelector('.popup__wrapper');


  singleoffer.forEach((el) => {
    el.addEventListener('click', (e) => {

      var yScrollWidth = (window.innerWidth - document.body.clientWidth) + 'px';
      html.style.paddingRight = yScrollWidth;
      html.classList.add('popup--open');
      document.querySelector('.header').classList.remove('fixed');

      singleOfferPopup.classList.add('active');

      let offerID = e.currentTarget.dataset.open;
      $.ajax({
        url: `/wp-json/wp/v2/job_offers/${offerID}`,
        type: 'GET',
        success: function(data) {

          console.log(data);
          const postTitle = data.title.rendered;
          const postDesc = data.content.rendered;
          // Retrieve the date of publication
          const datePublished = data.date;
          // Convert the date string to a Date object
          const date = new Date(datePublished);

          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear().toString();
          const formattedDate = `${day}.${month}.${year}`;


          // Retrieve the ACF field of type link
          const linkField = data.aplikuj;
       
          
          singleOfferContent.innerHTML = `
          
          <header class="job-offer__offer-header">
          <h3 class="job-offer__popup-offer-name">${postTitle}</h3>
          <p class="job-offer__popup-offer-pub">Opublikowano: ${formattedDate}</p>
          </header>
          <div class="job-offer__popup-offer-content">
          ${postDesc}
          <a href="${linkField.url}" class="btn btn--fill">${linkField.title}</a>
          </div>
        `;
        }, error: function( xhr, textStatus, errorThrown ) {
          console.log( xhr.responseText );
         
        }
      });

   

      const singleOfferPopupClose = document.querySelector('.popup-close-btn');

      singleOfferPopupClose.addEventListener('click', () => {
        singleOfferPopup.classList.remove('active');

        setTimeout(()=>{
          html.style.paddingRight = 0;
          html.classList.remove('popup--open');
        }, 300);
      });

    });
  });
}

offerPopup();





/* Blog - bizea */

const productTab = document.querySelectorAll('.product-tab__header, .cat-exp, .category-expand-btn, .search-button');
if(productTab) {
productTab.forEach(tab => {

    tab.addEventListener('click', ()=>{
      tab.classList.toggle('active');
      
      let content =  document.querySelector(`.${tab.dataset.open}`)
  
      content.classList.toggle('expanded');
     
      if(tab.classList.contains('search-button') && content.classList.contains('expanded')){
        $("html, body").animate({ scrollTop: 0 }, "slow");
      }
    })
  })
}

const showMoreHis = document.querySelector('#read-more-history');
if(showMoreHis) {


  showMoreHis.addEventListener('click', ()=>{
    showMoreHis.classList.toggle('active');

      if(showMoreHis.classList.contains('active')){
        showMoreHis.innerHTML = showMoreHis.dataset.after;
      }else{
        showMoreHis.innerHTML = showMoreHis.dataset.before;

      }
      let content =  document.querySelector(`.${showMoreHis.dataset.open}`)
  
      content.classList.toggle('expanded');
     
    })
  }



// main menu 


const subMenu = document.querySelectorAll('#products, #serwis,#products-mob, #serwis-mob');
if(subMenu) {
  subMenu.forEach(elem => {

    elem.addEventListener('click', ()=>{
      elem.classList.toggle('sub-open');
      let content =  document.querySelector(`.${elem.dataset.open}`)
      
      subMenu.forEach(other => {
        let otherContent =  document.querySelector(`.${other.dataset.open}`)
        if(otherContent.classList.contains('active') && content != otherContent){
          otherContent.classList.remove('active');
          other.classList.remove('sub-open');
        }

      })

      content.classList.toggle('active');
      if(content.classList.contains('active')){
          html.classList.add('submenu--open');
        }else{
          
          html.classList.remove('submenu--open');
      }
     
    })
  })

  document.addEventListener('click', (e) => {
    if(e.target.id == 'overlay'){
      let menu = document.querySelector('.sub-open');
      let content =  document.querySelector(`.${menu.dataset.open}`)
      menu.classList.remove('sub-open');
      content.classList.remove('active');
      html.classList.remove('submenu--open');
    }
  })
}

const submenuReturn = document.querySelectorAll('.submenu__return');
if(submenuReturn){
  submenuReturn.forEach(button => {
    button.addEventListener('click', () => {
      const active = document.querySelectorAll('.submenu.active');
      active.forEach(sub => {sub.classList.remove('active')});
    })
  })
}

const menuSerwices = document.querySelectorAll('.serwis__list .menu-item');

if(menuSerwices){
  menuSerwices.forEach(serwis => {
    serwis.addEventListener('click', () => {
     serwis.querySelector('.serwis__btn').click();
    })
  })
}



const lazyLoadFn = () => {
  $('.lazy').Lazy({
    effect: 'fadeIn',
    visibleOnly: false,
    scrollDirection: 'both',
    autoDestroy: true,
    afterLoad: function (element) {
      $(element).removeClass('lazy-loading lazy');
      $('.img-post-wrapper').css('opacity', 1);
    },
    onFinishedAll: function () {
      $('.lazy-loading').removeClass('lazy-loading lazy');
      $('.img-post-wrapper').css('opacity', 1);
    }
  });
};

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const html = document.querySelector("html");

const header =  document.querySelector('.header');
const headerHamburger =  document.querySelector('.header .hamburger');
const headerMenuPopup =  document.querySelector('.header .menu-popup__container--mobile');

if(headerMenuPopup) {
  headerHamburger.addEventListener('click', ()=>{
    headerHamburger.classList.toggle('hamburger--open');
    headerMenuPopup.classList.toggle('active');
    header.classList.toggle('fixed-open');
  
    html.classList.toggle('mob-nav-open');
    document.body.classList.toggle('mobile-open-body');
  });
  html.addEventListener('click', (e) => {
   
    if(e.target.classList.contains('mobile-open-body')){
        headerHamburger.click();
    }
  })
}


const menuMobileSubmenus = document.querySelectorAll('.menu-popup__container--mobile .menu .menu-item-has-children');

// menuMobileSubmenus.forEach(el => {
//   el.addEventListener('click', (e) => {
//     if(e.target != e.currentTarget) return;

//     e.currentTarget.classList.toggle('active');
//   });
// })

function teamMembersPopupInit() {
  const singleTeamMember = document.querySelectorAll('.team-page .subpage__team-single');
  const teamPopup = document.querySelector('.team__popup');
  const teamPopupContent = document.querySelector('.team__popup-content');
  const teamPopupWrapper = document.querySelector('.team__popup-wrapper');


  singleTeamMember.forEach((el) => {
    el.addEventListener('click', (e) => {
      var yScrollWidth2 = (window.innerWidth - document.body.clientWidth) + 'px';
      html.style.paddingRight = yScrollWidth2;
      html.classList.add('popup--open');
      document.querySelector('.header').classList.remove('fixed');

      let id = e.currentTarget.dataset.id;
      id = id.replace('team-member-', '');

      let teamMembersData = teamMembersDataArray[id];
      let name = teamMembersData.name;
      let position = teamMembersData.position;
      let description = teamMembersData.description;

      let image = teamMembersData.image;
      let image_popup = teamMembersData.image_popup;
      let imageContent = '';

      if (image_popup) {
        imageContent = `<img src="${image_popup}" />`;
      } else if(image) {
        imageContent = `<img src="${image}" />`;
      }

      let linkedin = teamMembersData.linkedin;
      let linkedinContent = '';

      let hostname = window.location.hostname;
      let protocol = window.location.protocol;
      let pathname = window.location.pathname;

      let pageLink = '';
      if(pathname.includes('bizea')) {
        pageLink = protocol +'//'+ hostname +'/bizea';
      } else {
        pageLink = protocol +'//'+ hostname;
      }


      if (linkedin) {
        linkedinContent = `<a href="${linkedin}" class="team__popup-content-linkedin">
          <img src="${pageLink}/wp-content/themes/bizea/img/team/linkedin.svg" />
        </a>`;
      }

      teamPopupContent.innerHTML = `
        <button type="button" class="team__popup-close-btn"></button>
        <div class="team__popup-content-col-left">
          <strong class="team__popup-content-name"> ${name}</strong>
          <span class="team__popup-content-position"> ${position}</span>
          ${linkedinContent}
          <div class="team__popup-content-img-wrapper">
            ${imageContent}
          </div>

        </div>

        <div class="team__popup-content-col-right">
          <div class="team__popup-content-description">
            ${description}
          </div>
        </div>
      `;

      const teamPopupClosebtn = document.querySelector('.team__popup-close-btn');

      teamPopupClosebtn.addEventListener('click', () => {
        teamPopupWrapper.classList.remove('active');

        setTimeout(()=>{
          html.style.paddingRight = 0;
          html.classList.remove('popup--open');
        }, 300);
      });

      teamPopupWrapper.classList.add('active');
      let yScrollWidth = (window.innerWidth - document.body.clientWidth) + 'px';
      html.style.paddingRight = yScrollWidth;
      html.classList.add('popup--open');
    });
  });
}

teamMembersPopupInit();


function wonCasesPopupInit() {
  const singleWonCase = document.querySelectorAll('.single-won-case');
  const wonCasesPopup = document.querySelector('.won-cases__popup');
  const wonCasesPopupContent = document.querySelector('.won-cases__popup-content');
  const wonCasesPopupWrapper = document.querySelector('.won-cases__popup-wrapper');


  singleWonCase.forEach((el) => {
    el.addEventListener('click', (e) => {

      var yScrollWidth = (window.innerWidth - document.body.clientWidth) + 'px';
      html.style.paddingRight = yScrollWidth;
      html.classList.add('popup--open');
      document.querySelector('.header').classList.remove('fixed');

      let id = e.currentTarget.dataset.id;
      id = id.replace('won-cases-', '');

      let wonCasesData = wonCasesDataArray[id];
      let name = wonCasesData.name;
      let date = wonCasesData.date;
      let description = wonCasesData.description;
      let link = wonCasesData.link;

      wonCasesPopupContent.innerHTML = `
        <button type="button" class="won-cases__popup-close-btn"></button>
        <div class="won-cases__popup-content-row-top">
          <strong class="won-cases__popup-content-name"> ${name}</strong>
          <a class="won-cases__popup-content-link" href="${link}" target="_blank">https://orzeczenia.nsa.gov.pl</a>
          <span class="won-cases__popup-content-date"> ${date}</span>
        </div>

        <div class="won-cases__popup-content-row-bottom">
          <div class="won-cases__popup-content-description">
            ${description}
          </div>
        </div>
      `;

      const wonCasesPopupClosebtn = document.querySelector('.won-cases__popup-close-btn');

      wonCasesPopupClosebtn.addEventListener('click', () => {
        wonCasesPopupWrapper.classList.remove('active');

        setTimeout(()=>{
          html.style.paddingRight = 0;
          html.classList.remove('popup--open');
        }, 300);
      });

      wonCasesPopupWrapper.classList.add('active');
    });
  });
}

wonCasesPopupInit();


const blogTabs = document.querySelectorAll('.blog-page__posts-tabs li');
const blogTabContentContainers = document.querySelectorAll('.blog-page__posts-container');

if(blogTabs.length > 0) {
  blogTabs.forEach(el => {
    el.addEventListener('click', (e)=> {
      const id = e.currentTarget.dataset.id;
      const container = document.querySelector('#'+id);

      blogTabContentContainers.forEach(el =>{
        el.classList.remove('active');
      });

      container.classList.add('active');

      blogTabs.forEach(el =>{
        el.classList.remove('active');
      });

      e.currentTarget.classList.add('active');
    });
  });
}

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const postType = urlParams.get('type')

if(postType){
  blogTabs.forEach(el =>{
    const id = el.dataset.id;

    if(postType == id ) {
      el.click();
    }
  });
}


/* Practice area */

const pacticeAreaTabs = document.querySelectorAll('.practice-areas-page__content-tabs li');
const pacticeAreaLists = document.querySelectorAll('.practice-areas-page__content-list-wrapper');
const pacticeAreaContentWrappers = document.querySelectorAll('.practice-areas-page__content-single-wrapper');
const pacticeAreaContents = document.querySelectorAll('.practice-areas-page__content-single');
const pacticeAreaListsItems = document.querySelectorAll('.practice-areas-page__content-list li');

const pacticeAreaInfoBox = document.querySelector('.practice-areas-page__content-info-box');
const pacticeAreaInfoBoxClose = document.querySelector('.practice-areas-page__content-info-box-close');

pacticeAreaTabs.forEach((el)=>{
  el.addEventListener('click', (e) => {
    const id = e.currentTarget.dataset.id;

    pacticeAreaTabs.forEach(el =>{
      el.classList.remove('active');
    });

    e.currentTarget.classList.add('active');

    pacticeAreaLists.forEach(el => {
      el.classList.remove('active');
    });

    const list = document.querySelector('#list-'+id);
    list.classList.add('active');

    pacticeAreaContentWrappers.forEach(el => {
      el.classList.remove('active');
    });

    const content = document.querySelector('#content-'+id);
    content.classList.add('active');

    pacticeAreaInfoBox.classList.add('not-active');

    if(document.querySelector('.practice-areas-page__content-list li.last.active.single-item-'+id)) {
      pacticeAreaInfoBox.classList.remove('not-active');
    }
  });
})

pacticeAreaListsItems.forEach((el)=>{
  el.addEventListener('click', (e) => {
    const id = e.currentTarget.dataset.id;
    const type = e.currentTarget.dataset.type;

    pacticeAreaListsItems.forEach(el =>{
      if(el.classList.contains("single-item-"+type)) {
        el.classList.remove('active');
      }

      if(el.classList.contains("last")) {
        pacticeAreaInfoBox.classList.add('not-active');
      }
    });


    if(el.classList.contains("last")) {
      pacticeAreaInfoBox.classList.remove('not-active');
    }

    e.currentTarget.classList.add('active');

    pacticeAreaContents.forEach(el => {
      if(el.classList.contains("single-content-"+type)) {
        el.classList.remove('active');
      }
    });

    const content = document.querySelector('#content-'+id);
    content.classList.add('active');
  });
})

if(pacticeAreaInfoBox) {
  pacticeAreaInfoBoxClose.addEventListener('click', () => {
    pacticeAreaInfoBox.classList.toggle('hide');
  });
}


const practiceArea = urlParams.get('area')

if(practiceArea){
  pacticeAreaTabs.forEach(el =>{
    const id = el.dataset.id;

    if(practiceArea == id ) {
      el.click();
    }
  });
}



const contentAreasMobile = document.querySelectorAll('.practice-areas-page__content-areas > li > span');
const contentAreasContentListElemsMobile = document.querySelectorAll('.practice-areas-page__content-areas .content-list > li > span');
const contentAreasGoBackMobile = document.querySelectorAll('.practice-areas-page__content-areas .content-wrapper > span');

contentAreasMobile.forEach(el => {
  el.addEventListener('click', () => {
    const list = el.parentNode.querySelector('.content-list');
    list.classList.toggle('active');

    el.parentNode.classList.toggle('active');
  });
})

contentAreasContentListElemsMobile.forEach(el => {
  el.addEventListener('click', () => {
    const contentWrapper = el.parentNode.querySelector('.content-wrapper');
    contentWrapper.classList.add('active');

    const contentList = el.parentNode.parentNode;
    contentList.classList.add('active-child');

    const contentGoBack = contentWrapper.querySelector('span');
    const content = contentWrapper.querySelector('.content-single');

    const contentListHeight = contentList.offsetHeight ;
    const contentGoBackHeight = contentGoBack.offsetHeight;

    const height = contentListHeight - contentGoBackHeight;

    content.style.height = height+"px";
  });
})

contentAreasGoBackMobile.forEach(el => {
  el.addEventListener('click', () => {
    el.parentNode.classList.remove('active');

    const contentList = el.parentNode.parentNode.parentNode;
    contentList.classList.remove('active-child');
  });
})


/* RODO */

const rodoContents = document.querySelectorAll('.rodo-page__content-single');
const rodoAreaListsItems = document.querySelectorAll('.rodo-page__content-list li');

rodoAreaListsItems.forEach((el)=>{
  el.addEventListener('click', (e) => {
    const id = e.currentTarget.dataset.id;

    rodoAreaListsItems.forEach(el =>{
      el.classList.remove('active');
    });

    e.currentTarget.classList.add('active');

    rodoContents.forEach(el => {
        el.classList.remove('active');
    });

    const content = document.querySelector('#content-'+id);
    content.classList.add('active');
  });
})


const rodoAreaListsItemsMobile = document.querySelectorAll('.rodo-page__content-rodo-list li span');

rodoAreaListsItemsMobile.forEach((el)=>{
  el.addEventListener('click', (e) => {
    e.currentTarget.parentNode.classList.toggle('active');
  });
})


/* BLOG */

const blogCategories = document.querySelectorAll('.blog-page__posts .categories-list__item');

blogCategories.forEach(el => {
  el.addEventListener('click', (e)=>{
    blogCategories.forEach(el => { el.classList.remove('active') });
    e.currentTarget.classList.add('active');
  })
})


/* Form files */

const fileCVInput = document.querySelector('#file-cv');
const fileLabel = document.querySelector('#file-label');

if(fileCVInput) {
  fileCVInput.addEventListener('change', (e)=> {

    if(e.currentTarget.files[0]) {
      fileLabel.textContent = e.currentTarget.files[0].name;
      fileLabel.classList.remove('btn--gray');
      fileLabel.classList.add('btn--dark');
    } else {
      fileLabel.textContent = 'Załącz plik';
      fileLabel.classList.add('btn--gray');
      fileLabel.classList.remove('btn--dark');
    }
  });
}


const fileAttachmentInput = document.querySelector('#file-attachments');

if(fileAttachmentInput) {
  fileAttachmentInput.addEventListener('change', (e)=> {
    console.log('dsadsa');
    if(e.currentTarget.files[0]) {
      fileLabel.textContent = e.currentTarget.files[0].name;
      fileLabel.classList.remove('btn--gray');
      fileLabel.classList.add('btn--dark');
    } else {
      fileLabel.textContent = 'Załącz dokumenty';
      fileLabel.classList.add('btn--gray');
      fileLabel.classList.remove('btn--dark');
    }
  });
}